import React from 'react';
import { graphql, Link } from 'gatsby';
import BodyClassName from 'react-body-classname';

import temp from '../images/tempgc.png';
import ShareIcon from '../images/share-icon';
import bg from '../images/bg-controvercy.png';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';

export default function Gcbooks({ data }) {
  const chaptersList = data?.allPrismicBookChapter?.nodes;

  return (
    <BodyClassName className="body-light page-watch">
      <DefaultLayoutComponent title="Watch">
        <div className="gcBooksWrapper ">
          <div className="gcGradientBg">
            <section className="gcPagebg">
              <div className="gcBgTitleAndIntro">
                <div className="gcBreadCrumb">
                  <Link to="/">
                    <p className="gcBreadCrumbnav">Home /</p>
                  </Link>
                  <p className="gcBreadCrumbnav">Books /</p>
                  <p className="gcBreadCrumbCurrentnav ">The Great Controversery </p>
                </div>
                <h3 className="gcPageTitle">The Great Controversery</h3>
                <h6 className="gcShortDesc">Some detail about the book here</h6>
                <div className="gcShareIcon">
                  <ShareIcon />
                </div>
              </div>
            </section>
          </div>

          <div className=" wrapper wrapper-xl pd-x-md">
            <div className="gcPageInner">
              <h3 className="gcCardListHeader">CHAPTERS</h3>
              <section className="gccardListHolder">
                {chaptersList?.map((chapter, idx) => {
                  const title = chapter?.data?.chapterTitle[0]?.text;
                  const imgUrl = chapter?.data?.thumbnailImage?.url;
                  return (
                    <div className="singleChapterContainer">
                      <div className="gcCardImageContainer">
                        <img src={imgUrl} />
                        <div className="gcstatusNotification ">
                          <h6 className="gcstatusText">5 MIN READ</h6>
                        </div>
                      </div>
                      <div className="gcCardTitle">
                        <h6 className="gcCardChapter">Chapter {idx + 1}</h6>
                        <h6 className="gcCardheading">{title}</h6>
                      </div>
                    </div>
                  );
                })}
              </section>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
}

export const query = graphql`
  {
    allPrismicBookChapter(
      filter: { data: { chapter_of_book: { uid: { eq: "the-great-controversy" } } } }
      sort: { fields: data___display_priority, order: ASC }
    ) {
      nodes {
        data {
          chapterTitle: chapter_title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
        }
      }
    }
  }
`;
